import React from 'react'
import Team from './Team'
export default function About() {
  React.useEffect(()=>{
    window.scroll(0,0);
  })
  return (
    <> 
          <section id="cta" className="cta">
      <div className="container" data-aos="zoom-in">

        <div className="text-center">
  
        </div>

      </div>
    </section>
          <section id="about" class="about" >
      <div class="container" data-aos="fade-up">

        <div class="row">
          <div class="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">

            <img src="./assets/img/about.jpg" class="img-fluid" alt=""/>
            {/* public\assets\img\New folder\a(1).jpg */}
          </div>
          <div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right" data-aos-delay="100">
            <h3>Happy Home Decor </h3>
            <p class="fst-italic">
              Happy Home Decor came into existence in the year 2021, Morbi, a city of Gujarat. Since then, the company has established a strong foothold in Home decore Marketing owing to its firm commitment towards quality.
            </p>
            <ul>
              <li><i class="ri-check-double-line"></i> Happy Home Decor is provide a quality-oriented Materials of Decor items</li>
              <li><i class="ri-check-double-line"></i> A wide range of Sofa,Mattress ,curtain,WakllPaper,Bedsheet design is available </li>
              <li><i class="ri-check-double-line"></i> Also  Availble wide range of Chimani</li>
              <li><i class="ri-check-double-line"></i> Association with the trusted sources</li>
              <li><i class="ri-check-double-line"></i> Able to cater bulk demands</li>
            </ul>
 
          </div>
        </div>

      </div>
      </section>
    <Team/>
    </>

  )
}
