import React from 'react'
import About from './About'
import Slider from './slider'

export default function Home() {
  React.useEffect(()=>{
    window.scroll(0,0);
  })
  return (
    <>

     <Slider/>
     <About/>
    
    </>
  )
}
