import React from   'react'
import { BrowserRouter as Router, Routes, Route, } from "react-router-dom";
 import Home from './Componants/Home';
import Footer from './Componants/footer';
import About from './Componants/About';
import Header from './Componants/header';
import Product from './Componants/Product';
import Contect from './Componants/contect';




function App() {
  return (
    <>
      <Router>
        <Header />
       <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="about" element={<About/>} />
          <Route path="Product/:ProductUrl" element={<Product/>} />
          <Route path="Contect" element={<Contect/>} />
        </Routes>
        <Footer />
      </Router>
  </>
  );
}

export default App;

// import './App.css';
// import Navbar from './componants/Navbar';
// import Foter from './componants/Foter';
// import About from './componants/About';
// import Designs from './componants/Designs';
// import Contect from './componants/Contect';
// import Home from './componants/Home';
// import PVC_PIPE_CONNECTER from './componants/PVC_PIPE_CONNECTER';
// import MUKHVAS_DANI from './componants/MUKHVAS_DANI';
// import SINK_COUPLING from './componants/SINK_COUPLING';
// import ELECTRIC_BOARD from './componants/ELECTRIC_BOARD';
// import KITCHEN_STAND from './componants/KITCHEN_STAND';



// // https://stackoverflow.com/questions/34418254/how-do-i-add-an-active-class-to-a-link-from-react-router



// import { BrowserRouter as Router, Routes, Route, } from "react-router-dom";





// function App() {
 

//   return (
//     <>
//       <div className="App">
//         <Router>
//           <Header />
//           <Routes>
//             <Route path="/" element={<Home/>} />
//            
//           </Routes>
         
//            <Footer/>
//         </Router>
       

//       </div>
//     </>
//   );
// }

// export default App;
