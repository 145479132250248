import React from "react";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";

const url ="https://thehappyhomedecor.com/ad_happyhomedecor/Api/Product/Images/";
const Alldata = () => {
  React.useEffect(() => {
    window.scroll(0, 0);
  });
  
  const [loader, setloader] = useState(false);
  let params = useParams();
  console.log(params.ProductUrl);
  
  const Product = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(url + params.ProductUrl);
      return data;
    } catch (error) {
      console.log(error);
    }
  };
  const [product, setproduct] = useState([]);
  //console.log('data '+params.ProductUrl);
  
  useEffect(() => {
    setloader(true);
    Product().then((data) => {
      setproduct(data);
      setloader(false);
    });
  }, [params.ProductUrl]);
  
//console.log(product)
const formatedimage = product.map((data)=>(
  <>
     
    <div className="col-lg-4 col-md-6 portfolio-item filter-web">
            <div className="portfolio-wrap">
              <img  src={"https://thehappyhomedecor.com/ad_happyhomedecor/Images/product/"+data.Path} className="img-fluid" alt=" img(sofa)"/>
              <div className="portfolio-info">
                <h4>{data.ProductName}</h4>
                <p></p>
                <div className="portfolio-links">
                  <a href={"https://thehappyhomedecor.com/ad_happyhomedecor/Images/product/"+data.Path} data-gallery="portfolioGallery" className="portfolio-lightbox" title="Web 3"><i className="bx bx-plus"></i></a>
                </div>
              </div>
            </div>
          </div>     
    </>
  ))
  

  return (
    <>
    <div>
       <section id="cta" className="cta">
      <div className="container" data-aos="zoom-in">

        <div className="text-center">
  
        </div>

      </div>
    </section>

    <section id="portfolio" className="portfolio">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>Portfolio</h2>
          <p>Check our {params.ProductUrl} Desing</p>
        </div>

     
        <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="200">

        {loader?(
          <h2 class="container" style={{marginLeft:"450px"}} >Loading....</h2>
      ):(

        <>
         {formatedimage}
          </>
      )}
        </div>

      </div>
    </section>

    </div>

   </>
  );
};

export default Alldata;