// <!-- ======= Hero Section ======= -->

import React from "react";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";

const url ="https://thehappyhomedecor.com/ad_happyhomedecor/Api/Slider/";

const Alldata = () => {
  React.useEffect(() => {
    window.scroll(0, 0);
  });

  const [loader, setloader] = useState(false);
  let params = useParams();

  const Slider = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(url);
      return data;
    } catch (error) {
      console.log(error);
    }
  };
  
  const [slider, setSlider] = useState([]);
  
  useEffect(() => {
    setloader(true);
    Slider().then((data) => {
      setSlider(data);
      setloader(false);
    });
  }, []);
  const formattedSlider =
  <Carousel autoPlay infiniteLoop='true'>
      {
        slider.map( data => {
          return <div>
            <img alt="..."src={"https://thehappyhomedecor.com/ad_happyhomedecor/Images/slider/"+data.Image}/>
          </div>
        })
      }
    </Carousel>

    return (
        <>
            <section id="hero" className="d-flex align-items-center justify-content-center " style={{padding : '50px 0 10px  0'}}>

            <div className="h" style={{"marginTop": "5vh"}} data-aos="fade-up">
                    {formattedSlider}
                </div>
            </section>
</>
    )
}

export default Alldata;