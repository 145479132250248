import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
//import "../App.css";
const Contactus = async () => {
  try {
    const {data: { data }} = await axios.get(`https://thehappyhomedecor.com/ad_happyhomedecor/Api/Contact/`);
    return data;
  } 
  catch (error) {
    console.log(error);
  }
};

export default function Contect() {
  React.useEffect(() => {
    window.scroll(0, 0);
  });

  const [contact, setcontact] = useState([]);

  useEffect(() => {
    Contactus().then((data) => {
      setcontact(data);
    });
  },[]);

  const [text, setText] = useState("");
  const HandleOnclick = () => {
    contact.map((data) => {
      window.open(`https://wa.me/+91${data.ContactNumber}?text=${text}`, "_blank");
      setText("");
    })
  };
  const Handleonchange = (event) => {
    setText(event.target.value);
  };

  const filtercontact = contact.map((data) => (
    <>

        <div>
      <section id="cta" className="cta">
        <div className="container" data-aos="zoom-in">
          <div className="text-center"></div>
        </div>
      </section>
      <section id="contact" class="contact">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h2>Contact</h2>
            <p>Contact Us</p>
          </div>

          <div>
            <iframe
              class=" w-100 reactabout3"
              src={data.AddressLink}
              title="myFrame"
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </div>

          <div class="row mt-5">
            <div class="col-lg-4">
              <div class="info">
                <div class="address">
                  <i class="bi bi-geo-alt"></i>
                  <h4>Location:</h4>
                  <p>
                    36,Orchid Sky,Club o7 Road,Shela, Ahmedabad, Gujarat 380058
                  </p>
                </div>

                <div class="email">
                  <i class="bi bi-envelope"></i>
                  <h4>Email:</h4>
                  <p>{data.EmailID}</p>
                </div>

                <div class="phone">
                  <i class="bi bi-phone"></i>
                  <h4>Call:</h4>
                  <p>+91 {data.ContactNumber}</p>
                </div>
              </div>
            </div>

            <div class="col-lg-8 mt-5 mt-lg-0">
              <form class="php-email-form">
                <div class="row">
                  <div class="col-md-6 form-group">
                    {/* <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" required> */}
                  </div>
                  <div class="col-md-6 form-group mt-3 mt-md-0">
                    {/* <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" required> */}
                  </div>
                </div>
                <div class="form-group mt-3">
                  {/* <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" required> */}
                </div>
                <div class="form-group mt-3">
                  <textarea
                    class="form-control"
                    name="message"
                    rows="5"
                    placeholder="Message"
                    required
                    value={text}
                    onChange={Handleonchange}
                  ></textarea>
                </div>
                <div class="my-3">
                  <div class="loading">Loading</div>
                  <div class="error-message"></div>
                  <div class="sent-message">
                    Your message has been sent. Thank you!
                  </div>
                </div>
                <div class="text-center">
                  <button
                    type="button"
                    value="Send Message"
                    onClick={HandleOnclick}
                  >
                    Send - Message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  </>
  ));

  return (
    <>
    {filtercontact}
    </>
  );
}
