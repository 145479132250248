import React from 'react'

export default function Team() {
  return (
    <> 
 <section id="team" class="team">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Team</h2>
          <p>Check our Team</p>
        </div>

        <div class="row">

          <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div class="member" data-aos="fade-up" data-aos-delay="100">
              <div class="member-img">
                <img src="assets/img/team/team (1).jpg" class="img-fluid" alt="eaf"/>
                <div class="social">
                  <a href="https://www.facebook.com/profile.php?id=100006363469157"><i class="bi bi-facebook"></i></a>
                  <a href="https://www.instagram.com/bhaveshransariya/?utm_medium=copy_link"><i class="bi bi-instagram"></i></a>
                
                </div>
              </div>
              <div class="member-info">
                <h4>Akash Nesadiya</h4>
                <span>Co-paartaner</span>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div class="member" data-aos="fade-up" data-aos-delay="100">
              <div class="member-img">
                <img style={{height : '80%'}} src="assets/img/team/team (2).jpg" class="img-fluid" alt="eaf"/>
                <div class="social">
                  <a href="https://www.facebook.com/profile.php?id=100006363469157"><i class="bi bi-facebook"></i></a>
                  <a href="https://www.instagram.com/bhaveshransariya/?utm_medium=copy_link"><i class="bi bi-instagram"></i></a>
                
                </div>
              </div>
              <div class="member-info">
                <h4>Akash Rangapariya</h4>
                <span>Co-paartaner</span>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div class="member" data-aos="fade-up" data-aos-delay="100">
              <div class="member-img">
                <img src="assets/img/team/team (3).jpg" class="img-fluid" alt="eaf"/>
                <div class="social">
                  <a href="https://www.facebook.com/profile.php?id=100006363469157"><i class="bi bi-facebook"></i></a>
                  <a href="https://www.instagram.com/bhaveshransariya/?utm_medium=copy_link"><i class="bi bi-instagram"></i></a>
                
                </div>
              </div>
              <div class="member-info">
                <h4>Bhavesh Ransariya</h4>
                <span>Co-paartaner</span>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
    </>

  )
}
