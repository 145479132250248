import React from 'react';
import { NavLink} from 'react-router-dom';
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from 'react';

const nav = {
  backgroundColor:"rgba(52,58,62,0.7)"
}

const products = async () => {
  try {
    const {data: { data }} = await axios.get(`https://thehappyhomedecor.com/ad_happyhomedecor/Api/Product/`);
    return data;
  } catch (error) {
    console.log(error);
  }
};
//export default
export default function Header() {

  
  const [product, setproduct] = useState([]);

  useEffect(() => {
    products().then((data) => {
      setproduct(data);
    });
  },[]);

  
   const filterproduct = product.map((data,i)=>(
        <li>
          <NavLink className="nav-link scrollto" to={"Product/"+ data.ProductUrl}>
            {data.ProductName}
          </NavLink>
      </li>       
   ))



  return (
    <>   {/* <!-- ======= Header ======= --> */}
    <header  style={nav} id="header" className="fixed-top "> 
      <div className="container d-flex align-items-center justify-content-lg-between">
  
        <h1 className="logo me-auto me-lg-0"><NavLink to="/" >Happy Home Decor<span>.</span></NavLink></h1>
        {/* <NavLink to="index.html" className="logo me-auto  me-lg-0"><img alt="..." src="assets/img/Logo/logo (4).png" style={{width : '100px', height:'400px'}} className="img-fluid"/></NavLink> */}
        <nav id="navbar" data-toggle="collapse"  className="navbar order-last order-lg-0">
          <ul class="">
            <li><NavLink className="nav-link scrollto " to="/">Home</NavLink></li>
            <li><NavLink className="nav-link scrollto" to="/about">About</NavLink></li>
            <li className="dropdown"><NavLink to={"Product/"}><span>Product</span> <i className="bi bi-chevron-down"></i></NavLink>
              <ul>
                  {filterproduct}
              </ul>
            </li>
            <li><NavLink className="nav-link scrollto" to="/contect">Contact</NavLink></li>
          </ul>
          <i className="bi bi-list mobile-nav-toggle"></i>
         </nav>
  
  
  
        {/* <!-- .navbar --> */}   
      </div>
    </header>
     {/* <!-- End Header -->  */}
     </> 
  );
}
