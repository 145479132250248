import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, NavLink} from 'react-router-dom';
   

const products = async () => {
  try {
    const {data: { data }} = await axios.get(`https://thehappyhomedecor.com/ad_happyhomedecor/Api/Product/`);
    console.log(data);
    return data;
  } catch (error) {
    console.log(error);
  }
};

const Footer = async () => {
  try {
    const {data: { data }} = await axios.get(`https://thehappyhomedecor.com/ad_happyhomedecor/Api/Contact/`);
    console.log(data);
    return data;
  } 
  catch (error) {
    console.log(error);
  }
};



function Alldata() {
  React.useEffect(() => {
    window.scroll(0, 0);
  });

  const [product, setproduct] = useState([]);

  useEffect(() => {
    products().then((data) => {
      setproduct(data);
    });
  },[]);

  
   const filterproduct = product.map((data,i)=>(
        
        <li>
          <i className="bx bx-chevron-right"></i>
          <NavLink className="" to={"Product/"+ data.ProductUrl}>
            {data.ProductName}
          </NavLink>
      </li>       
   ))

  const [footer, setfooter] = useState([]);

  useEffect(() => {
    Footer().then((data) => {
      setfooter(data);
    });
  },[]);
  
  const [text, setText] = useState("");
  
  
  const HandleOnclick = () => {
    footer?.map((data) => {
      window.open(`https://wa.me/+91${data.ContactNumber}?text=${text}`, "_blank");
      setText("");
    })
  };
   const Handleonchange = (event) => {

       setText(event.target.value);
     }
   
  let footerDetails = footer.map((data) => (
    <>
         <footer id="footer">
    <div className="footer-top">
      <div className="container">
        <div className="row">

          <div className="col-lg-3 col-md-6">
            <div className="footer-info">
              <h4>HAPPY HOME DECOR<span>.</span></h4>
      
              <p>
              {data.Address}<br/><br/>
                <strong>Phone:</strong>{data.ContactNumber}<br/>
                
              </p>
              <div className="social-links mt-3">
                {/* <a href="https://www.facebook.com/people/%C3%85k%C3%A0sh-N%C3%A9s%C3%A0diy%C3%A4/100025239979205/" className="facebook" target="_blanck"><i className="bx bxl-facebook"></i></a> */}
                <a href={data.InstagramLink} className="instagram" target="_blanck"><i className="bx bxl-instagram"></i></a>
                <a href={data.WhatsappLink} className="google-plus"target="_blanck"><i className="bx bxl-whatsapp"></i></a>
              </div>
      </div>
          </div>

          <div className="col-lg-2 col-md-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li><i className="bx bx-chevron-right"></i> <Link to="/">Home</Link></li>
              <li><i className="bx bx-chevron-right"></i> <Link to="/about">About us</Link></li>
              <li><i className="bx bx-chevron-right"></i> <Link to="/contect">Contact</Link></li>
            </ul>
          </div>

          <div className="col-lg-3 col-md-6 footer-links">
            <h4>Our Services</h4>
            <ul>
              {filterproduct}
            </ul>
          </div>

          <div className="col-lg-4 col-md-6 footer-newsletter">
            <h4>Our Newsletter</h4>
            <p>Happy Home Decor, Morbi</p>
            <form action="" method="post">
              <input type="text" value={text} onChange={Handleonchange} />
              <input type="button" value="Send Message"  onClick={HandleOnclick}/>
            </form>

          </div>

        </div>
      </div>
    </div>

    <div className="container">
      {/* <div className="copyright">
        &copy; Copyright <strong><span><Link to="/">HAPPY HOME DECOR</Link></span></strong>. All Rights Reserved
      </div> */}
      {/* <div className="credits">
       <!-- All the links in the footer should remain intact. -->
        <!-- You can delete the links only if you purchased the pro version. -->
        <!-- Licensing information: https://bootstrapmade.com/license/ -->
        <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/gp-free-multipurpose-html-bootstrap-template/ --> 
        DEVELOPED by <strong><span><Link to="/"></Link></span></strong><br/>
        <Link to="/"><h6 className='mt-1'>Prince Goswami & Ravi Vadaliya</h6></Link>
        <a href="tel:+917874579457"><h6>+91 78745 79457</h6></a>
      </div> */}
    </div>
    
  </footer>
    </>
  ))
   return (
        <>
          {footerDetails}
        </>
    );
  }
  
  export default Alldata;
